import React, { useEffect, useState } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import sIcon from "../../assets/images/sicon1.svg";
import sIcon2 from "../../assets/images/sicon2.svg";
import sIcon3 from "../../assets/images/sicon3.svg";
import sIcon4 from "../../assets/images/sicon4.svg";

import SideBar from "./../../base-components/SideBar";
import Header from "./../../base-components/Header";
import Chart from "./Chart";
import StockCircle from "./StockCircle";

const products = [
  { title: "07 LPO’s (Priority) pending approval", time: "2hrs ago", id: 1 },
  { title: "Tent Card stock updated", time: "3hrs ago", id: 1 },
  { title: "Project Redbull budget added", time: "2hrs ago", id: 1 },
];
function Login() {
  const navigate = useNavigate();
  const { getLoggedObject } = useAuth();
  const [loading, setLoading] = useState(false);
  const [darkmode, setDarkMode] = useState(2);

  const [data, setData] = useState([]);
  const [ntifications, setNotifications] = useState([]);
  const [orders, setOrders] = useState([]);

  const [stocktotal, setStockTotal] = useState(0);
  const [postotal, setPosTotal] = useState(0);
  const [bespoktotal, setBesPokTotal] = useState(0);

  const [stocktotalline, setStockTotalLine] = useState("");
  const [postotalline, setPosTotalLine] = useState("");
  const [bespoktotaline, setBesPokTotalLine] = useState("");

  const [stocktotalperc, setStockTotalperc] = useState(0);
  const [postotalperc, setPosTotalperc] = useState(0);
  const [bespoktotaperc, setBesPokTotalperc] = useState(0);

  const [morebutton, setMoreButton] = useState(0);

  useEffect(() => {
    const DarkValue = localStorage.getItem("DarkMode");
    if (DarkValue === null || parseInt(DarkValue) === 0) {
      setDarkMode(2);
    } else {
      setDarkMode(1);
    }
  }, []);

  useEffect(() => {
    if (getLoggedObject()?.ac_type == 9) {
      window.location.href = `${window.location.origin}/dashboard/suppliers`;
      return;
    } else {
      fetchOrders();
    }
  }, []);

  const fetchOrders = async () => {
    const payload = {
      token: getLoggedObject()?.token,
    };
    const { isError, data } = await doPost(
      payload,
      "auth/fetch_dashboard_data"
    );
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setData(data?.data);
        setNotifications(data?.notifications);
        setOrders(data?.order);

        setStockTotal(data?.stock_total);
        setPosTotal(data?.pos_total);
        setBesPokTotal(data?.bespok_total);

        setStockTotalLine(data?.stock_line);
        setPosTotalLine(data?.pos_line);
        setBesPokTotalLine(data?.bes_line);

        setStockTotalperc(data?.stock_perc);
        setPosTotalperc(data?.pos_perc);
        setBesPokTotalperc(data?.bes_perc);

        setMoreButton(data?.all_order_count);

        setLoading(false);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  return (
    <div className="container">
      <div className="wrapper-full">
        <div className="page-content">
          <SideBar />
          <div className="right_side_bar">
            <Header title={"Dashboard Overview"} />

            <div className="content_inner">
              <div className="dashboard_boxes_wrap">
                {/* FIRST BOX */}
                <div className="card p20 ">
                  <div className="dashboard_heading">Today’s Sales</div>
                  <small className="smal_dash">Sales Summary</small>

                  <div className="boxes_dashboard_first">
                    <div className="dash_box_left">
                      <img src={sIcon} />
                      <span className="count_stats">{data.total}</span>
                      <span className="text_stats">Total Orders</span>
                      {/* <span className="compare_stats orange">+8% than last month</span> */}
                    </div>
                    <div className="dash_box_left">
                      <img src={sIcon2} />
                      <span className="count_stats">{data.created}</span>
                      <span className="text_stats">Orders Created</span>
                      {/* <span className="compare_stats ferozie">+18 from yesterday</span> */}
                    </div>
                    <div className="dash_box_left">
                      <img src={sIcon3} />
                      <span className="count_stats">{data.completed}</span>
                      <span className="text_stats">Completed</span>
                      {/* <span className="compare_stats pink">12 delivery confirmed</span> */}
                    </div>
                    <div className="dash_box_left">
                      <img src={sIcon4} />
                      <span className="count_stats">{data.approval}</span>
                      <span className="text_stats">Approval Pending</span>
                      {/* <span className="compare_stats skyblue">07 marked as priority</span> */}
                    </div>
                  </div>
                </div>

                {/* SEOCND BOX */}
                <div className="card p20 ">
                  <div className="dashboard_heading">Notifications</div>
                  <div className="notifx_wrap">
                    {ntifications.length == 0 && (
                      <>
                        <div className="no_text_cnter">
                          No Notifications found!
                        </div>
                      </>
                    )}

                    {ntifications.map((v, index) => (
                      <>
                        <div className="notif_div">
                          <div className="left_not">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="6"
                              height="6"
                              viewBox="0 0 6 6"
                              fill="none"
                            >
                              <circle cx="3" cy="3" r="3" fill="#9D0C2F" />
                            </svg>
                            <span
                              dangerouslySetInnerHTML={{ __html: v.title }}
                            ></span>
                          </div>
                          <span className="time_notf">{v.ago}</span>
                        </div>
                      </>
                    ))}
                  </div>
                </div>

                {/* THIRD BOX */}
                <div className="card p20 ">
                  <div className="dashboard_heading">Order Management</div>

                  <div className="responsive_table mt-10px dashboard_responsive">
                    <table className="wd100 table_wrap">
                      <tr>
                        <th>#</th>
                        <th>Product</th>
                        {getLoggedObject()?.ac_type == 2 && (
                          <>
                            <td>Qty.</td>
                          </>
                        )}
                        <th>Project</th>
                        <th>Amount</th>
                        {getLoggedObject()?.ac_type != 4 && (
                          <>
                            <th>Vendor</th>
                            <th>LPO</th>
                          </>
                        )}
                        <th>Review</th>
                      </tr>
                      {orders.map((v, index) => (
                        <tr>
                          <td>{v.order_id}</td>
                          <td>
                            {v.products.map((v, i) => (
                              <>
                                <span className="product_data">
                                  {v.pro_title}
                                </span>
                              </>
                            ))}
                          </td>
                          {getLoggedObject()?.ac_type == 2 && (
                            <>
                              <td>
                                {v.products.map((v, i) => (
                                  <>
                                    <span className="product_data">
                                      {v.qty} Pcs
                                    </span>
                                  </>
                                ))}
                              </td>
                            </>
                          )}
                          <td>{v.project}</td>
                          <td>{parseFloat(v.total_amount).toFixed(2)}</td>
                          {getLoggedObject()?.ac_type != 4 && (
                            <>
                              <td>{v.vendor.companyname}</td>
                              <td>{v.lpo}</td>
                            </>
                          )}
                          <td>
                            <span
                              className="blue_link"
                              onClick={() => navigate("/order/details/" + v.id)}
                            >
                              <u>Details</u>
                            </span>
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                  {morebutton > 4 && (
                    <>
                      <div
                        className="text-center"
                        style={{ marginTop: "15px" }}
                      >
                        <Link to="/orders">View More</Link>
                      </div>
                    </>
                  )}
                </div>

                {/* FOURTH ITEM */}
                <div className="card p20 ">
                  <div className="dashboard_heading">Orders Insights</div>
                  <div className="mt-20px">
                    <Chart />
                  </div>
                </div>
              </div>

              <div className="bottom_dashboard mt-20px">
                <div className="bottm_dash_wrap">
                  <div className="card p20">
                    <div className="dashboard_heading">Stock Items</div>
                    <small className="smal_dash">Total Spend</small>

                    <div className="bottom_stats">
                      <div className="larget_stats red">AED {stocktotal}</div>
                      <div className="small_stas">{stocktotalline}</div>
                      <div className="meter">
                        <StockCircle
                          percentage={stocktotalperc}
                          color={"#d15356"}
                        />
                        {/* <img src={darkmode==1?meter_light:meter} /> */}
                      </div>
                    </div>
                  </div>

                  <div className="card p20">
                    <div className="dashboard_heading">Bespoke Orders</div>
                    <small className="smal_dash">Total Spend</small>

                    <div className="bottom_stats">
                      <div className="larget_stats greens">
                        AED {bespoktotal}
                      </div>
                      <div className="small_stas">{bespoktotaline}</div>
                      <div className="meter">
                        <StockCircle
                          percentage={bespoktotaperc}
                          color={"#3f824f"}
                        />
                        {/* <img src={darkmode==1?meter2_light:meter2} /> */}
                      </div>
                    </div>
                  </div>

                  <div className="card p20">
                    <div className="dashboard_heading">POS Orders</div>
                    <small className="smal_dash">Total Spend</small>

                    <div className="bottom_stats">
                      <div className="larget_stats greys">AED {postotal}</div>
                      <div className="small_stas">{postotalline}</div>
                      <div className="meter">
                        <StockCircle
                          percentage={postotalperc}
                          color={"#a6a6a6"}
                        />
                        {/* <img src={darkmode==1?meter3_light:meter3} /> */}
                      </div>
                    </div>
                  </div>

                  <div className="card p20">
                    <div className="dashboard_heading">Top Products</div>
                    <div className="bottom_stats">
                      <div className="responsive_table mt-10px">
                        <table className="wd100 table_wrap">
                          <tr>
                            <th>#</th>
                            <th>Name</th>
                          </tr>

                          {2 == 3 &&
                            products.map((v, index) => (
                              <tr>
                                <td>0{index + 1}</td>
                                <td style={{ width: "115px" }}>Keychain C2P</td>

                                <td>
                                  <span className="box_pro_per">46%</span>
                                </td>
                              </tr>
                            ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
