export const urls = {
  // API: "http://192.168.1.10/ProcX-Backend/",
  // PHP_API: "http://192.168.1.10/ProcX-Backend/",

  // API: "https://procx.co/admin/",
  // PHP_API: "https://procx.co/admin/",

  API: "https://prox.dedevelopers.org/admin/",
  PHP_API: "https://prox.dedevelopers.org/admin/",

  // API: "http://192.168.1.52/Codeigniter/ProcX/admin/",
  // PHP_API: "http://192.168.1.52/Codeigniter/ProcX/admin/",
};
