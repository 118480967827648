import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost, check_role_restrictions } from "./../../utils/apiCalls";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import SideBar from "./../../base-components/SideBar";
import Header from "./../../base-components/Header";
import { AiFillEdit, AiFillDelete, AiFillCloseCircle } from "react-icons/ai";
import CSVFILE from "../../assets/images/logo.svg";
import { urls } from "./../../utils/Api_urls";
import DeletePopup from "./../Settings/Delete";

function Login() {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const { getLoggedObject, isLoggedIn } = useAuth();
  const [upload, setUpload] = useState(false);
  const [edit, setEdit] = useState(0);
  const [delpopup, setDelPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [role, setRole] = useState(0);
  const [popup, setPopup] = useState(false);
  const [btnloading, setBtnLoading] = useState(false);
  const [csvfile, setCSVFile] = useState("");

  const [delid, setDelID] = useState(0);
  const [delpopupdel, setDelPopupDel] = useState(false);
  const [CustomerSelected, setCustomerSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [AllselPopup, setAllSelPopup] = useState(false);

  const path = window.location.pathname;
  const lastSlashIndex = path.lastIndexOf("/");
  const slugFromUrl =
    lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : "";

  const fetchData = async () => {
    setLoading(true);
    if (isLoggedIn) {
      try {
        const response = await check_role_restrictions();
        if (response == 5) {
          toast.error("You are not authorized to perform this action!");
          setLoading(true);
          navigate("/dashboard");
        } else {
          setRole(response);
          await do_get_brands_info();
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error:", error);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [isLoggedIn]);

  const do_get_brands_info = async () => {
    const payload = {
      budget: "0",
      token: getLoggedObject()?.token,
    };
    const { isError, data } = await doPost(
      payload,
      "auth/do_get_customers_info"
    );
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setSuppliers(data?.data);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const do_click_file = () => {
    fileInputRef.current.click();
  };

  const handle_submit = () => {
    toast.success("Brand has been created successfully!");
    setUpload(false);
    setEdit(0);
  };

  const do_make_inactive = async (id) => {
    setDelPopup(true);
    // toast.success("Vendor status been updated successfully!")
  };

  const handle_edit = async (id) => {
    setUpload(true);
    setEdit(1);
  };

  const update_file_csv = (e) => {
    let files = e.target.files;
    if (files.length > 0) {
      let fileReader = new FileReader();
      fileReader.readAsDataURL(files[0]);
      fileReader.onload = (event) => {
        setCSVFile(event.target.result);
      };
    }
  };

  const handle_csv = async () => {
    if (csvfile == "") {
      toast.error("Please upload a CSV File!");
      return;
    }
    setBtnLoading(true);
    const payload = {
      file: csvfile,
      token: getLoggedObject()?.token,
      update: 1,
    };
    const { isError, data } = await doPost(
      payload,
      "auth/bulk_upload_customer"
    );
    if (isError) {
      setBtnLoading(false);
      toast.error("Something went wrong with server.");
    } else {
      if (data.action == "success") {
        toast.success(
          edit == 0
            ? "Bulk Customers uploaded successfully!"
            : "Product information updated successfully!"
        );
        setPopup(false);
        do_get_brands_info();
        setBtnLoading(false);
      } else {
        toast.error(data?.error);
        setBtnLoading(false);
      }
    }
  };

  const handle_delete_popup = (val) => {
    setDelID(val.id);
    setDelPopupDel(true);
  };

  const handle_del = async () => {
    const payload = {
      id: delid,
      token: getLoggedObject()?.token,
    };
    const { isError, data } = await doPost(
      payload,
      "auth/delete_customer_by_id"
    );
    if (isError) {
      toast.error("Something went wrong with server.");
    } else {
      if (data.action == "success") {
        toast.success("Customer information deleted successfully!");
        await do_get_brands_info();
        setEdit(0);
        setDelID(0);
        setUpload(false);
        setDelPopupDel(false);
      } else {
        toast.error(data?.error);
      }
    }
  };
  const close_handle = () => {
    setDelID(0);
    setDelPopupDel(false);
  };

  //============ Filter Area ==========

  const handleSearch = (e) => {
    setsearchText(e.target.value);
  };

  const [searchText, setsearchText] = useState("");
  const [FilterCoustmers, setFilterCoustmers] = useState([]);

  const filterCoutmers = () => {
    const searchTextLowerCase = searchText.toLowerCase();
    const filteredCoustmersResult = suppliers.filter(
      (supplier) =>
        supplier.account_number.toLowerCase().includes(searchTextLowerCase) ||
        supplier.name.toLowerCase().includes(searchTextLowerCase)
    );
    setFilterCoustmers(filteredCoustmersResult);
  };

  useEffect(() => {
    filterCoutmers();
  }, [searchText, suppliers]);

  ///-------------- handle Routes

  const handleRoutes = (id) => {
    if (getLoggedObject().ac_type == 2 || getLoggedObject().ac_type == -1) {
      navigate(`/add-customers?id=${id}`);
    } else {
      navigate(`/customers/update/${id}`);
    }
  };

  ///-------------- handle selections

  // Handle individual checkbox selection
  const handleSelectCustomer = (e, customer) => {
    const { id } = customer;
    if (e.target.checked) {
      setCustomerSelected((prev) => [...prev, id]);
    } else {
      setCustomerSelected((prev) => prev.filter((cid) => cid !== id));
    }
  };

  // Handle "Select All" checkbox
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allIds = FilterCoustmers.map((v) => v.id);
      setCustomerSelected(allIds);
      setSelectAll(true);
    } else {
      setCustomerSelected([]);
      setSelectAll(false);
    }
  };

  const handleDeleteAll = async () => {
    if (CustomerSelected.length === 0) {
      toast.error("Please select at least one customer!");
      return;
    }
    // setLoading(true);
    const payload = {
      token: getLoggedObject()?.token,
      ids: CustomerSelected,
    };
    setAllSelPopup(false);
    setLoading(true);
    try {
      const { isError, data } = await doPost(
        payload,
        "auth/delete_customers_by_id"
      );
      if (isError) {
        toast.error("Something went wrong with server.");
      } else {
        if (data.action == "success") {
          toast.success("Customers deleted successfully!");
          fetchData();
        } else {
          toast.error(data?.error);
        }
      }
    } catch (error) {
      toast.error("Something went wrong with server.");
    } finally {
      setLoading(false);
      setAllSelPopup(false);
    }
  };

  const handleSelectDelClose = () => {
    setAllSelPopup(false);
  };

  return (
    <div className="container">
      <div className="wrapper-full">
        <div className="page-content">
          <SideBar />
          <div className="right_side_bar">
            <Header fun={handleSearch} title={"Customers Management"} />

            <div className="content_inner">
              <div className="">
                <div className="update_list">
                  {CustomerSelected.length > 0 && (
                    <button
                      onClick={() => setAllSelPopup(true)}
                      className="btn_delete"
                    >
                      Delete
                    </button>
                  )}

                  <button
                    className="button_submit"
                    onClick={() => setPopup(!popup)}
                  >
                    Update List
                  </button>
                </div>
                <div className="card wd100 p10">
                  <div className="responsive_table">
                    <table className="wd100 table_wrap">
                      <tr>
                        {(getLoggedObject().ac_type == 2 ||
                          getLoggedObject().ac_type == -1) && (
                          <th>
                            {" "}
                            <input
                              type="checkbox"
                              onChange={handleSelectAll}
                              checked={selectAll}
                            />{" "}
                          </th>
                        )}
                        <th>Account Number</th>
                        <th>Account Name</th>
                        <th>Parent Account</th>
                        <th>KAM</th>
                        <th>Contact</th>
                        <th>Contact</th>
                        <th></th>
                      </tr>
                      {suppliers.length == 0 && (
                        <>
                          <tr>
                            <td colSpan={7} className="no_data">
                              No Data found!
                            </td>
                          </tr>
                        </>
                      )}
                      {FilterCoustmers.map((v) => (
                        <tr>
                          {(getLoggedObject().ac_type == 2 ||
                            getLoggedObject().ac_type == -1) && (
                            <td>
                              {" "}
                              <input
                                id={v.id}
                                type="checkbox"
                                onChange={(e) => handleSelectCustomer(e, v)}
                                checked={CustomerSelected.includes(v.id)}
                              />{" "}
                            </td>
                          )}
                          <td>
                            <span className="wrap_flex_td">
                              <span
                                className="blue_link"
                                onClick={() =>
                                  navigate("/customer/details/" + v.id)
                                }
                              >
                                {v?.account_number}
                              </span>
                            </span>
                          </td>
                          <td>{v?.name}</td>
                          <td>{v?.parent}</td>
                          <td>{v?.kam_name}</td>
                          <td>{v?.contact}</td>
                          <td>{v?.phone}</td>
                          <td>
                            <AiFillEdit
                              onClick={() => handleRoutes(v?.id)}
                              size={18}
                              style={{ marginRight: "5px", cursor: "pointer" }}
                            />
                            {(getLoggedObject()?.ac_type == -1 ||
                              getLoggedObject()?.ac_type == 2) && (
                              <>
                                <AiFillDelete
                                  onClick={() => handle_delete_popup(v)}
                                  size={15}
                                  className="delete_icon_color"
                                />
                              </>
                            )}
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </div>
                {delpopup && (
                  <>
                    <div className="popup_outer">
                      <div className="popup_inner">
                        <div className="popup_white_center">
                          <div className="modal_heading">
                            <span>Change Status</span>
                            <span
                              className="close_icon"
                              onClick={() => setDelPopup(false)}
                            >
                              <AiFillCloseCircle />
                            </span>
                          </div>
                          <div className="form_setcion">
                            <div className="form-group">
                              <label>Comment/Reason</label>
                              <textarea
                                name="company_name"
                                className="setting_input textarea_input"
                                placeholder="Please enter comment/reason of inactive"
                              ></textarea>
                            </div>
                            <div className="">
                              <button
                                type="button"
                                className="button_submit"
                                onClick={() => setDelPopup(false)}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {popup && (
                  <>
                    <div className="popup_outer">
                      <div className="popup_inner">
                        <div className="popup_white_center">
                          <div className="modal_heading">
                            <span>Update Customers List</span>
                            <span
                              className="close_icon"
                              onClick={() => setPopup(false)}
                            >
                              <AiFillCloseCircle />
                            </span>
                          </div>
                          <div className="form_setcion">
                            <div className="form-group">
                              <label>Upload CSV File</label>
                              <div
                                className="image_browse_button"
                                style={{ width: "100%" }}
                                onClick={() => do_click_file()}
                              >
                                {csvfile == "" ? (
                                  <>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M7.27269 5.84638C7.74361 5.53172 8.29726 5.36377 8.86364 5.36377C9.22727 5.36377 9.52204 5.65855 9.52204 6.02218C9.52204 6.38581 9.22727 6.68059 8.86364 6.68059C8.5577 6.68059 8.25864 6.7713 8.00427 6.94127C7.7499 7.11124 7.55164 7.35282 7.43456 7.63546C7.31749 7.91811 7.28685 8.22912 7.34654 8.52917C7.40622 8.82923 7.55354 9.10485 7.76987 9.32117C7.9862 9.5375 8.26181 9.68482 8.56187 9.7445C8.86192 9.80419 9.17293 9.77356 9.45558 9.65648C9.73822 9.53941 9.9798 9.34115 10.1498 9.08677C10.3197 8.8324 10.4105 8.53334 10.4105 8.22741C10.4105 7.86378 10.7052 7.569 11.0689 7.569C11.4325 7.569 11.7273 7.86378 11.7273 8.22741C11.7273 8.79378 11.5593 9.34743 11.2447 9.81836C10.93 10.2893 10.4828 10.6563 9.9595 10.8731C9.43624 11.0898 8.86046 11.1465 8.30497 11.036C7.74948 10.9255 7.23923 10.6528 6.83874 10.2523C6.43825 9.85181 6.16552 9.34156 6.05503 8.78607C5.94453 8.23058 6.00124 7.6548 6.21798 7.13154C6.43472 6.60828 6.80176 6.16104 7.27269 5.84638Z"
                                        fill="#8D98AA"
                                      />
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M23.1692 9.18164C23.5276 9.18164 23.8181 9.47822 23.8181 9.84408V15.3909C23.8181 18.2285 23.2693 20.3871 21.8733 21.8201C20.4765 23.254 18.3713 23.818 15.6052 23.818H9.12196C6.35673 23.818 4.25161 23.2542 2.85457 21.8205C1.45838 20.3876 0.909058 18.229 0.909058 15.3909V13.1607C0.909058 12.7948 1.1996 12.4983 1.55799 12.4983C1.91639 12.4983 2.20693 12.7948 2.20693 13.1607V15.3909C2.20693 18.0996 2.73916 19.8233 3.77474 20.8861C4.80947 21.948 6.48633 22.4931 9.12196 22.4931H15.6052C18.2417 22.4931 19.9186 21.9478 20.9531 20.8859C21.9884 19.823 22.5203 18.0993 22.5203 15.3909V9.84408C22.5203 9.47822 22.8108 9.18164 23.1692 9.18164Z"
                                        fill="#8D98AA"
                                      />
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M2.88158 2.86617C4.2972 1.46169 6.43034 0.90918 9.23439 0.90918H13.6183C13.9797 0.90918 14.2727 1.19988 14.2727 1.55847C14.2727 1.91706 13.9797 2.20775 13.6183 2.20775H9.23439C6.55948 2.20775 4.85718 2.74258 3.80709 3.7844C2.75701 4.82622 2.21793 6.51513 2.21793 9.16898C2.21793 9.52757 1.92493 9.81827 1.5635 9.81827C1.20206 9.81827 0.909058 9.52757 0.909058 9.16898C0.909058 6.387 1.46595 4.27065 2.88158 2.86617Z"
                                        fill="#8D98AA"
                                      />
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M15.5454 4.72718C15.5454 4.37573 15.8504 4.09082 16.2266 4.09082H22.5006C22.8768 4.09082 23.1818 4.37573 23.1818 4.72718C23.1818 5.07864 22.8768 5.36355 22.5006 5.36355H16.2266C15.8504 5.36355 15.5454 5.07864 15.5454 4.72718Z"
                                        fill="#8D98AA"
                                      />
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M19.3637 0.90918C19.7151 0.90918 20 1.21415 20 1.59035V7.86437C20 8.24057 19.7151 8.54554 19.3637 8.54554C19.0122 8.54554 18.7273 8.24057 18.7273 7.86437V1.59035C18.7273 1.21415 19.0122 0.90918 19.3637 0.90918Z"
                                        fill="#8D98AA"
                                      />
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M18.7588 13.4293L14.2551 17.3279L14.2431 17.3381C13.6956 17.7895 13.0085 18.0363 12.2995 18.0363C11.5905 18.0363 10.9035 17.7895 10.3559 17.3381C10.35 17.3332 10.3442 17.3282 10.3385 17.3231L9.99081 17.0153C9.69968 16.7804 9.34208 16.6432 8.96864 16.6232C8.59289 16.6031 8.22049 16.7028 7.90482 16.9079L2.56182 20.5242C2.26358 20.7261 1.85852 20.6474 1.6571 20.3485C1.45567 20.0497 1.53416 19.6438 1.83239 19.4419L7.18878 15.8166C7.7369 15.4583 8.38457 15.2841 9.03805 15.319C9.69153 15.354 10.317 15.5963 10.824 16.0109C10.8306 16.0163 10.837 16.0218 10.8434 16.0275L11.1921 16.3362C11.505 16.591 11.8961 16.7303 12.2995 16.7303C12.7041 16.7303 13.0962 16.5903 13.4095 16.3341L17.9133 12.4354L17.9252 12.4253C18.4728 11.9739 19.1598 11.7271 19.8688 11.7271C20.5779 11.7271 21.2649 11.9739 21.8124 12.4253L21.8244 12.4354L23.5925 13.9661C23.8648 14.2019 23.8949 14.6142 23.6596 14.8872C23.4243 15.1601 23.0128 15.1902 22.7405 14.9544L20.9788 13.4293C20.6655 13.1731 20.2734 13.0331 19.8688 13.0331C19.4643 13.0331 19.0722 13.1731 18.7588 13.4293Z"
                                        fill="#8D98AA"
                                      />
                                    </svg>
                                    <span> Upload your File</span>
                                  </>
                                ) : (
                                  <div className="image_upl_file">
                                    <img src={CSVFILE} />
                                  </div>
                                )}
                              </div>
                              <input
                                type="file"
                                name="logo"
                                id="logo"
                                accept=".csv"
                                ref={fileInputRef}
                                style={{ display: "none" }}
                                onChange={update_file_csv}
                              />
                            </div>

                            <small
                              style={{
                                marginBottom: "10px",
                                display: "block",
                                float: "right",
                              }}
                            >
                              <a
                                target="_blank"
                                href={urls.API + "customer.csv"}
                                download
                              >
                                Download Sample File (please follow this format)
                              </a>
                            </small>

                            <div className="">
                              <button
                                type="button"
                                className="button_submit"
                                onClick={() => handle_csv()}
                              >
                                Upload
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {delpopupdel && (
        <DeletePopup
          loading={loading}
          title={"Customer"}
          ondeleteCall={handle_del}
          onclosecall={close_handle}
        />
      )}

      {AllselPopup && (
        <DeletePopup
          loading={loading}
          title={"Customer"}
          ondeleteCall={handleDeleteAll}
          onclosecall={handleSelectDelClose}
        />
      )}
    </div>
  );
}

export default Login;
