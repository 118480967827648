import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost, check_role_restrictions } from "./../../utils/apiCalls";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import SideBar from "./../../base-components/SideBar";
import Header from "./../../base-components/Header";
import {
  AiFillDelete,
  AiOutlineHeart,
  AiOutlineMinus,
  AiOutlinePlus,
  AiFillHeart,
} from "react-icons/ai";
import DeletePopup from "./../Settings/Delete";

function Login() {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const { getLoggedObject, isLoggedIn } = useAuth();
  const [loading, setLoading] = useState(false);
  const [upload, setUpload] = useState(false);
  const [edit, setEdit] = useState(0);
  const [delpopup, setDelPopup] = useState(false);
  const [Selectdelpopup, setSelectedDelPopup] = useState(false);

  const [products, setProducts] = useState([]);
  const [productsfilter, setProductsFilter] = useState([]);
  const [pos, setPOS] = useState([]);
  const [posfilter, setPOSFilter] = useState([]);
  const [categories, setCategories] = useState([]);
  const [role, setRole] = useState(0);
  const [cat, setCat] = useState(0);

  const [cart, setCart] = useState([]);
  const [cartpos, setCartPOS] = useState([]);
  const [quantities, setQuantities] = useState({});
  const [delid, setDelID] = useState(0);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const path = window.location.pathname;
  const lastSlashIndex = path.lastIndexOf("/");
  const slugFromUrl =
    lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : "";

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("type")) {
      const viewValue = params.get("type");
      setEdit(viewValue);
    } else {
      setEdit(0);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (isLoggedIn) {
        try {
          const response = await check_role_restrictions();
          if (response == 5) {
            toast.error("You are not authorized to perform this action!");
            setLoading(true);
            navigate("/dashboard");
          } else {
            setRole(response);
            await do_get_product_stuff();
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
          console.error("Error:", error);
        }
      }
    };
    fetchData();
  }, [isLoggedIn]);

  const do_get_product_stuff = async () => {
    const payload = {
      token: getLoggedObject()?.token,
    };
    const { isError, data } = await doPost(
      payload,
      "auth/do_get_product_listings"
    );
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setProducts(data?.data);
        setProductsFilter(data?.data);
        setPOS(data?.pos);
        setFilteredPOS(data?.pos);
        setPOSFilter(data?.pos);
        setCategories(data?.categories);
        setLoading(false);
        setFilteredProducts(data?.data);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
    const storedCart = JSON.parse(localStorage.getItem("cartProducts")) || [];
    setCart(storedCart);
  };

  const handleIncrement = (productId) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: (prevQuantities[productId] || 1) + 1,
    }));
  };

  // Handle decrement of quantity
  const handleDecrement = (productId) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: Math.max((prevQuantities[productId] || 1) - 1, 1),
    }));
  };

  const handleQuantityChange = (productId, value) => {
    const newValue = Math.max(1, Number(value));
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: newValue,
    }));
  };

  const addToCart = (product) => {
    const productQty = quantities[product.id] || 1;
    if (productQty > product?.stock) {
      toast.error(
        "Insufficient stock available. Please choose a lower quantity."
      );
      return;
    }

    const existingProduct = cart.find((item) => item.id === product.id);

    if (existingProduct) {
      const updatedCart = cart.map((item) =>
        item.id === product.id ? { ...item, qty: item.qty + productQty } : item
      );
      setCart(updatedCart);
      if (slugFromUrl === "pos") {
        const updatedCartPOS = cartpos.map((item) =>
          item.id === product.id
            ? { ...item, qty: item.qty + productQty }
            : item
        );
        setCartPOS(updatedCartPOS);
        localStorage.setItem("cartPOS", JSON.stringify(updatedCartPOS));
      } else {
        localStorage.setItem("cartProducts", JSON.stringify(updatedCart));
      }
    } else {
      const newProduct = {
        id: product.id,
        title: product.title,
        price: product.amount,
        qty: productQty,
        image: product.image,
        customer: 0,
        vID: product.vID,
        min_order: product.min_order,
        max_order: product.max_order,
        lead_time: product.lead_time,
      };

      const updatedCart = [...cart, newProduct];
      setCart(updatedCart);

      if (slugFromUrl === "pos") {
        const updatedCartPOS = [...cartpos, newProduct];
        setCartPOS(updatedCartPOS);
        localStorage.setItem("cartPOS", JSON.stringify(updatedCartPOS));
      } else {
        localStorage.setItem("cartProducts", JSON.stringify(updatedCart));
      }
    }

    toast.success(`${product.title} added to cart!`);
  };

  //////////////////////////////////////

  const do_click_file = () => {
    fileInputRef.current.click();
  };

  const do_update_products = (val) => {
    setCat(val);
    if (val == 0) {
      setProducts(productsfilter);
    } else {
      const new_pridycts = productsfilter.filter(
        (product) => product.cID === val
      );
      setProducts(new_pridycts);
    }
  };

  useEffect(() => {
    if (slugFromUrl == "pos") {
      var cartItems = JSON.parse(localStorage.getItem("cartPOS")) || [];
      setCartPOS(cartItems);
    } else {
      var cartItems = JSON.parse(localStorage.getItem("cartProducts")) || [];
      setCart(cartItems);
    }
  }, [slugFromUrl]);

  const updateCart = (index, newQty) => {
    if (newQty === 0) {
      if (slugFromUrl === "pos") {
        const updatedCart = cartpos.filter((item, i) => i !== index);
        localStorage.setItem("cartPOS", JSON.stringify(updatedCart));
        setCartPOS(updatedCart);
      } else {
        const updatedCart = cart.filter((item, i) => i !== index);
        localStorage.setItem("cartProducts", JSON.stringify(updatedCart));
        setCart(updatedCart);
      }
    } else {
      if (slugFromUrl === "pos") {
        const updatedCart = [...cartpos];
        updatedCart[index].qty = newQty;
        localStorage.setItem("cartPOS", JSON.stringify(updatedCart));
        setCartPOS(updatedCart);
      } else {
        const updatedCart = [...cart];
        updatedCart[index].qty = newQty;
        localStorage.setItem("cartProducts", JSON.stringify(updatedCart));
        setCart(updatedCart);
      }
    }
  };

  const do_set_item_saved = async () => {
    navigate("/cart", {
      state: {
        id: slugFromUrl == "pos" ? 2 : 1,
      },
    });
  };

  const do_clear_items = async () => {
    if (slugFromUrl == "pos") {
      localStorage.removeItem("cartPOS");
      setCartPOS([]);
    } else {
      localStorage.removeItem("cartProducts");
      setCart([]);
    }
  };

  const do_add_wishlist = async (id) => {
    const payload = {
      token: getLoggedObject()?.token,
      id: id,
      type: slugFromUrl == "pos" ? 2 : 1,
    };
    const { isError, data } = await doPost(payload, "auth/do_add_wishlist");
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        do_get_product_stuff();
        toast.success(data?.data);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const handle_delete_popup = (val) => {
    setDelID(val.id);
    setDelPopup(true);
  };

  const handle_del = async () => {
    const payload = {
      id: delid,
      token: getLoggedObject()?.token,
    };
    const { isError, data } = await doPost(
      payload,
      "auth/delete_product_by_id"
    );
    if (isError) {
      toast.error("Something went wrong with server.");
    } else {
      if (data.action == "success") {
        toast.success("Product deleted successfully!");
        await do_get_product_stuff();
        setEdit(0);
        setDelID(0);
        setUpload(false);
        setDelPopup(false);
      } else {
        toast.error(data?.error);
      }
    }
  };
  const close_handle = () => {
    setDelID(0);
    setDelPopup(false);
  };

  const handleSearch = (e) => {
    setsearchText(e.target.value);
  };

  const [searchText, setsearchText] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filteredPOS, setFilteredPOS] = useState([]);

  // Filter products based on search text and category
  const filterProducts = () => {
    let filteredProductsResult = products.filter((product) =>
      product.title.toLowerCase().includes(searchText.toLowerCase())
    );
    if (cat !== 0) {
      filteredProductsResult = filteredProductsResult.filter(
        (product) => product.cID === cat
      );
    }
    setFilteredProducts(filteredProductsResult);
  };

  const filterPOS = () => {
    let filteredPOSResult = pos.filter((item) =>
      item.title.toLowerCase().includes(searchText.toLowerCase())
    );
    if (cat !== 0) {
      filteredPOSResult = filteredPOSResult.filter((item) => item.cID === cat);
    }
    setFilteredPOS(filteredPOSResult);
  };

  useEffect(() => {
    if (slugFromUrl === "products") {
      filterProducts();
    }
    if (slugFromUrl === "pos") {
      filterPOS();
    }
  }, [searchText, cat, slugFromUrl]);

  // Add to Cart

  const handleDeleteProduct = (e, v) => {
    e.preventDefault();
    e.stopPropagation();
    handle_delete_popup(v);
  };
  const handleSelectProduct = (e, v) => {
    e.preventDefault();
    e.stopPropagation();
    if (selectedProducts.includes(v.id)) {
      setSelectedProducts(
        selectedProducts.filter((productId) => productId !== v.id)
      );
    } else {
      setSelectedProducts([...selectedProducts, v.id]);
    }
  };

  const handleSelectAllToggle = (e) => {
    e.preventDefault();
    if (
      selectedProducts.length === filteredProducts.length ||
      selectedProducts.length === filteredPOS.length
    ) {
      setSelectedProducts([]);
    } else {
      if (slugFromUrl == "pos") {
        setSelectedProducts(filteredPOS.map((product) => product.id));
      } else {
        setSelectedProducts(filteredProducts.map((product) => product.id));
      }
    }
  };

  const handleSelectDelClose = () => {
    setSelectedDelPopup(false);
  };

  const isAllSelected =
    selectedProducts.length === filteredProducts.length ||
    selectedProducts.length === filteredPOS.length;

  const handleSelectDel = async () => {
    if (selectedProducts.length === 0) {
      toast.error("Please select at least one product to delete.");
      return;
    }
    const payload = {
      token: getLoggedObject()?.token,
      product_ids: selectedProducts,
    };

    setLoading(true);

    try {
      const { isError, data } = await doPost(
        payload,
        "auth/deleteBulkProducts"
      );
      if (isError) {
        toast.error("Something went wrong with server.");
        setLoading(false);
      } else {
        if (data.status === "success") {
          toast.success("Product deleted successfully!");
          do_get_product_stuff();
          setSelectedProducts([]);
        }
      }
    } catch (err) {
      console.log(err?.response?.message);
    } finally {
      setLoading(false);
      setSelectedDelPopup(false);
    }
  };

  useEffect(() => {
    setSelectedProducts([]);
  }, [slugFromUrl]);

  return (
    <div className="container">
      <div className="wrapper-full">
        <div className="page-content">
          <SideBar />
          <div className="right_side_bar">
            <Header
              fun={handleSearch}
              title={slugFromUrl == "pos" ? "POS Orders" : "Products"}
            />
            <div className="content_inner">
              {slugFromUrl == "products" ? (
                <div className="categories_list">
                  <>
                    <Link
                      onClick={() => do_update_products(0)}
                      className={`prod_cats ${cat == 0 ? "selected" : ""}`}
                    >
                      All
                    </Link>
                  </>
                  {categories.map((v, index) => (
                    <Link
                      onClick={() => do_update_products(v.id)}
                      className={`prod_cats ${v.id == cat ? "selected" : ""}`}
                    >
                      {v?.title}
                    </Link>
                  ))}
                </div>
              ) : (
                <></>
              )}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  justifyContent: "flex-end",
                }}
              >
                {selectedProducts.length > 0 && (
                  <button
                    onClick={() => setSelectedDelPopup(true)}
                    className="btn DelSelect"
                  >
                    Delete
                  </button>
                )}
                <div
                  onClick={handleSelectAllToggle}
                  className="checkbox-wrapper-15"
                >
                  <input
                    className="inp-cbx"
                    id="select-all"
                    type="checkbox"
                    checked={isAllSelected}
                    style={{ display: "none" }}
                    onChange={() => {}}
                  />
                  <label className="cbx" htmlFor="select-all">
                    <span>
                      <svg width="12px" height="9px" viewBox="0 0 12 9">
                        <polyline points="1 5 4 8 11 1"></polyline>
                      </svg>
                    </span>
                    <span>Select All</span>
                  </label>
                </div>
              </div>

              <div className="flex-space-between-start products_main_Wrap wd100">
                <div className="products_left_wrap">
                  {slugFromUrl == "products" && (
                    <>
                      {products.length == 0 && !loading && (
                        <>
                          <div className="no_data">No data found!</div>
                        </>
                      )}
                      {loading && (
                        <>
                          <div className="no_data">loading...</div>
                        </>
                      )}
                      {filteredProducts.map((v, index) => (
                        <div className="Cart_box_Pro">
                          <div
                            className="Pro_box_pro"
                            key={index}
                            onClick={(e) =>
                              navigate(
                                `${
                                  v.stock_text === "Out of stock"
                                    ? "#"
                                    : `/product/details/${v.id}`
                                }`
                              )
                            }
                          >
                            <div className="image_pro_box">
                              <img src={v.image} />
                              {(getLoggedObject()?.ac_type == -1 ||
                                getLoggedObject()?.ac_type == 2) && (
                                <>
                                  <div
                                    onClick={(e) => handleDeleteProduct(e, v)}
                                    className="heart_icon_abs left0"
                                  >
                                    <AiFillDelete
                                      size={15}
                                      className="delete_color"
                                    />
                                  </div>
                                </>
                              )}

                              {getLoggedObject()?.ac_type == -1 ||
                              getLoggedObject()?.ac_type == 2 ? (
                                <div className="heart_icon_abs">
                                  <div
                                    key={v.id}
                                    onClick={(e) => handleSelectProduct(e, v)}
                                    className="checkbox-wrapper-15 proselect"
                                  >
                                    <input
                                      className="inp-cbx"
                                      id={`cbx-${v.id}`}
                                      type="checkbox"
                                      checked={selectedProducts.includes(v.id)}
                                      style={{ display: "none" }}
                                      onChange={() => {}}
                                    />
                                    <label
                                      className="cbx"
                                      htmlFor={`cbx-${v.id}`}
                                    >
                                      <span>
                                        <svg
                                          width="12px"
                                          height="9px"
                                          viewBox="0 0 12 9"
                                        >
                                          <polyline points="1 5 4 8 11 1"></polyline>
                                        </svg>
                                      </span>
                                      <span></span>
                                    </label>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="heart_icon_abs"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    do_add_wishlist(v.id);
                                  }}
                                >
                                  {v.wish == 1 ? (
                                    <AiFillHeart color="#5CA399" />
                                  ) : (
                                    <AiOutlineHeart color="#5CA399" />
                                  )}
                                </div>
                              )}
                            </div>
                            <div className="name_pro_box">{v.title}</div>
                            <div className="price_pro_box flex-space-between">
                              <span className="amount_pro">{v.amount} AED</span>
                              <span
                                style={{
                                  color:
                                    v.stock_text == "Out of stock"
                                      ? "red"
                                      : v.stock_text == "Low stock"
                                      ? "#FFD580"
                                      : "",
                                }}
                                className="stock_pro"
                              >
                                {v.stock_text}
                              </span>
                            </div>
                          </div>
                          <div className="Main_Add_Card_Box">
                            <div className="Pro_Card_add">
                              <button
                                disabled={v.stock_text == "Out of stock"}
                                onClick={() => handleDecrement(v.id)}
                              >
                                -
                              </button>
                              <input
                                type="number"
                                min="1"
                                style={{
                                  width: "50px",
                                  textAlign: "center",
                                  border: "none",
                                  outline: "none",
                                  background: "transparent",
                                }}
                                value={quantities[v.id] || 1}
                                onChange={(e) =>
                                  handleQuantityChange(v.id, e.target.value)
                                }
                                className="quantity-input"
                              />
                              <button
                                disabled={v.stock_text == "Out of stock"}
                                onClick={() => handleIncrement(v.id)}
                              >
                                +
                              </button>
                            </div>
                            <button
                              onClick={() => addToCart(v)}
                              className="AddCartBtnMain"
                              disabled={v.stock_text == "Out of stock"}
                            >
                              Add to Cart
                            </button>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                  {/* to="/product/details/1?type=pos" */}

                  {slugFromUrl == "pos" && (
                    <>
                      {pos.length == 0 && !loading && (
                        <>
                          <div className="no_data">No data found!</div>
                        </>
                      )}
                      {loading && (
                        <>
                          <div className="no_data">loading...</div>
                        </>
                      )}
                    </>
                  )}
                  {slugFromUrl == "pos" &&
                    filteredPOS.map((v, index) => (
                      <>
                        {/* to={} */}
                        <div key={"pro_" + index} className="pro_box">
                          <div
                            onClick={(e) =>
                              navigate(
                                v.stock_text === "Out of stock"
                                  ? "#"
                                  : `/product/details/${v.id}`
                              )
                            }
                            className="image_pro_box"
                          >
                            <img src={v.image} />
                            {(getLoggedObject()?.ac_type == -1 ||
                              getLoggedObject()?.ac_type == 2) && (
                              <>
                                <div
                                  onClick={(e) => handleDeleteProduct(e, v)}
                                  className="heart_icon_abs left0"
                                >
                                  <AiFillDelete
                                    size={15}
                                    className="delete_color"
                                  />
                                </div>
                              </>
                            )}

                            {getLoggedObject()?.ac_type == -1 ||
                            getLoggedObject()?.ac_type == 2 ? (
                              <div className="heart_icon_abs">
                                <div
                                  key={v.id}
                                  onClick={(e) => handleSelectProduct(e, v)}
                                  className="checkbox-wrapper-15 proselect"
                                >
                                  <input
                                    className="inp-cbx"
                                    id={`cbx-${v.id}`}
                                    type="checkbox"
                                    checked={selectedProducts.includes(v.id)}
                                    style={{ display: "none" }}
                                    onChange={() => {}}
                                  />
                                  <label
                                    className="cbx"
                                    htmlFor={`cbx-${v.id}`}
                                  >
                                    <span>
                                      <svg
                                        width="12px"
                                        height="9px"
                                        viewBox="0 0 12 9"
                                      >
                                        <polyline points="1 5 4 8 11 1"></polyline>
                                      </svg>
                                    </span>
                                    <span></span>
                                  </label>
                                </div>
                              </div>
                            ) : (
                              <div
                                className="heart_icon_abs"
                                onClick={(event) => {
                                  event.preventDefault();
                                  do_add_wishlist(v.id);
                                }}
                              >
                                {v.wish == 1 ? (
                                  <AiFillHeart color="#5CA399" />
                                ) : (
                                  <AiOutlineHeart color="#5CA399" />
                                )}
                              </div>
                            )}
                          </div>
                          <div className="name_pro_box">{v.title}</div>
                          <div className="price_pro_box flex-space-between">
                            <span className="amount_pro">{v.amount} AED</span>
                            <span
                              style={{
                                color:
                                  v.stock_text == "Out of stock"
                                    ? "red"
                                    : v.stock_text == "Low stock"
                                    ? "#FFD580"
                                    : "",
                              }}
                              className="stock_pro"
                            >
                              {v.stock_text}
                            </span>
                          </div>
                          <div className="Main_Add_Card_Box">
                            <div className="Pro_Card_add">
                              <button
                                disabled={v.stock_text === "Out of stock"}
                                onClick={() => handleDecrement(v.id)}
                              >
                                -
                              </button>
                              <input
                                type="number"
                                min="1"
                                style={{
                                  width: "50px",
                                  textAlign: "center",
                                  border: "none",
                                  outline: "none",
                                  background: "transparent",
                                }}
                                value={quantities[v.id] || 1}
                                onChange={(e) =>
                                  handleQuantityChange(v.id, e.target.value)
                                }
                                className="quantity-input"
                              />

                              <button
                                disabled={v.stock_text === "Out of stock"}
                                onClick={() => handleIncrement(v.id)}
                              >
                                +
                              </button>
                            </div>
                            <button
                              onClick={() => addToCart(v)}
                              className="AddCartBtnMain"
                              disabled={v.stock_text === "Out of stock"}
                            >
                              Add to Cart
                            </button>
                          </div>
                        </div>
                      </>
                    ))}
                </div>
                {/* CART BOX */}
                <div className="cart_box card">
                  <div className="cart_header">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_280_15445)">
                          <path
                            d="M10.5311 11.4905H3.41574C3.27222 11.4905 3.15137 11.3697 3.15137 11.2186C3.15137 10.3046 3.86139 9.57195 4.7376 9.57195H9.09592C11.9738 9.57195 12.4119 7.75913 12.9482 5.12296C13.0993 4.36006 13.1748 3.98239 12.9935 3.72557C12.8122 3.46875 12.4572 3.46875 11.7472 3.46875H3.15137"
                            stroke="#D8D9DD"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M4.42785 9.57202L2.75099 1.55777C2.60747 0.98371 2.09384 0.583374 1.50467 0.583374H0.900391"
                            stroke="#D8D9DD"
                            stroke-linecap="round"
                          />
                          <path
                            d="M5.87823 13.4166C6.40801 13.4166 6.83752 12.9871 6.83752 12.4573C6.83752 11.9276 6.40801 11.498 5.87823 11.498C5.34843 11.498 4.91895 11.9276 4.91895 12.4573C4.91895 12.9871 5.34843 13.4166 5.87823 13.4166Z"
                            stroke="#D8D9DD"
                          />
                          <path
                            d="M10.5462 13.4166C11.076 13.4166 11.5055 12.9871 11.5055 12.4573C11.5055 11.9276 11.076 11.498 10.5462 11.498C10.0164 11.498 9.58691 11.9276 9.58691 12.4573C9.58691 12.9871 10.0164 13.4166 10.5462 13.4166Z"
                            stroke="#D8D9DD"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_280_15445">
                            <rect width="14" height="14" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    <span>Cart Details</span>
                  </div>
                  <div className=" cart_inner">
                    {slugFromUrl == "products" && cart.length == 0 && (
                      <div className="no_cart p10">CART IS EMPTY</div>
                    )}
                    {slugFromUrl == "pos" && cartpos.length == 0 && (
                      <div className="no_cart p10">CART IS EMPTY</div>
                    )}
                    <div className="mt-20px">
                      {slugFromUrl == "products" &&
                        cart.map((v, i) => (
                          <>
                            <div className="cart_items  flex-space-between">
                              <span>
                                {i + 1}. {v.title}
                              </span>
                              <span className="item_count">
                                <span onClick={() => updateCart(i, v.qty - 1)}>
                                  <AiOutlineMinus />
                                </span>
                                <span className="count_item">{v.qty}</span>
                                <span onClick={() => updateCart(i, v.qty + 1)}>
                                  <AiOutlinePlus />
                                </span>
                              </span>
                            </div>
                          </>
                        ))}
                      {slugFromUrl == "pos" &&
                        cartpos.map((v, i) => (
                          <>
                            <div className="cart_items  flex-space-between">
                              <span>
                                {i + 1}. {v.title}
                              </span>
                              <span className="item_count">
                                <span onClick={() => updateCart(i, v.qty - 1)}>
                                  <AiOutlineMinus />
                                </span>
                                <span
                                  className="count_item"
                                  contentEditable="true"
                                >
                                  {v.qty}
                                </span>
                                <span onClick={() => updateCart(i, v.qty + 1)}>
                                  <AiOutlinePlus />
                                </span>
                              </span>
                            </div>
                          </>
                        ))}
                    </div>
                    <div className="cart_cancel p10">
                      {slugFromUrl == "products" && cart.length > 0 && (
                        <>
                          <div className="item_cart_data_confirm ">
                            <button
                              type="button"
                              className="button_followup"
                              onClick={() =>
                                toast.success("Cart saved successfully!")
                              }
                            >
                              Save Items
                            </button>
                            <button
                              type="button"
                              className="button_followup"
                              onClick={() => do_set_item_saved()}
                            >
                              Confirm
                            </button>
                          </div>
                        </>
                      )}
                      {slugFromUrl == "pos" && cartpos.length > 0 && (
                        <>
                          <div className="item_cart_data_confirm ">
                            <button
                              type="button"
                              className="button_followup"
                              onClick={() =>
                                toast.success("Cart saved successfully!")
                              }
                            >
                              Save Items
                            </button>
                            <button
                              type="button"
                              className="button_followup"
                              onClick={() => do_set_item_saved()}
                            >
                              Confirm
                            </button>
                          </div>
                        </>
                      )}
                      <button
                        type="button"
                        className="button_followup wd100"
                        onClick={() => do_clear_items()}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {delpopup && (
        <DeletePopup
          title={"Product"}
          ondeleteCall={handle_del}
          onclosecall={close_handle}
        />
      )}

      {Selectdelpopup && (
        <DeletePopup
          loading={loading}
          title={"Product"}
          ondeleteCall={handleSelectDel}
          onclosecall={handleSelectDelClose}
        />
      )}
    </div>
  );
}

export default Login;
