import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";

import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import CopyIcon from "../../assets/images/icon-copy.svg";
import DelIcon from "../../assets/images/del.svg";
import Select from "react-select";

import SideBar from "./../../base-components/SideBar";
import Header from "./../../base-components/Header";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { urls } from "./../../utils/Api_urls";
import Loader from "../../base-components/loader/index.jsx";

function Login() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const { getLoggedObject } = useAuth();

  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(0);
  const [cart, setCart] = useState([]);
  const [cartpos, setCartPos] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [projectselect, setProjectselect] = useState("");

  const [update, setUpdate] = useState(false);
  const [total, setTotal] = useState(0);
  const [type, setType] = useState(state.id);
  const [options, setOptions] = useState([]);
  const [darkmode, setDarkMode] = useState(null);
  const [finaldate, setfinalDate] = useState(null);

  // React Select Style

  // ${darkmode === null ? "" : ""}

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: `${darkmode === null ? "#4D4F62" : "#C0C2C6"}`,
      border: state.isFocused ? "none" : "none",
      boxShadow: state.isFocused ? "none" : "none",
      fontSize: "10px",
      color: "#FFF",
      color: state.isFocused ? "#FF0000" : "#FFF",
      width: "200px",
      height: "10px",
      "&:hover": { borderColor: "none" },
    }),
    placeholder: (provided) => {
      return {
        ...provided,
        color: `${darkmode === null ? "#fff" : "#4c535f"}`,
      };
    },
    singleValue: (styles) => ({
      ...styles,
      color: `${darkmode === null ? "#fff" : "#4c535f"}`,
    }),
    menu: (provided, state) => ({
      ...provided,
      background: `${darkmode === null ? "#67697C" : "#C0C2C6"}`,
      color: `${darkmode === null ? "#fff" : "#4c535f"}`,
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected
        ? "#4D4F62"
        : state.isFocused
        ? "transparent"
        : "transparent",
      color: state.isSelected ? "#FFF" : "",
      "&:hover": {
        background: "#4D4F62",
        color: "#FFF",
      },
    }),
    input: (provided) => ({
      ...provided,
      color: `${darkmode === null ? "#ffffff8f" : "#4c535f"}`,
    }),
  };

  useEffect(() => {
    setType(state.id);
    get_all_customers();
    const params = new URLSearchParams(window.location.search);
    if (params.has("type")) {
      const viewValue = params.get("type");
      setEdit(viewValue);
    } else {
      setEdit(0);
    }
  }, [state]);

  const DarkValue = localStorage.getItem("DarkMode");
  useEffect(() => {
    setDarkMode(DarkValue);
  }, [DarkValue]);

  const path = window.location.pathname;
  const lastSlashIndex = path.lastIndexOf("/");
  const slugFromUrl =
    lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : "";

  const do_click_file = () => {
    fileInputRef.current.click();
  };

  function calculateTotalPrice(items) {
    let totalPrice = 0;
    items.forEach((item) => {
      totalPrice += item.price * item.qty;
    });
    setTotal(totalPrice);
  }

  useEffect(() => {
    if (type == 2) {
      var cartItems = JSON.parse(localStorage.getItem("cartPOS")) || [];
      setCart(cartItems);
    } else {
      var cartItems = JSON.parse(localStorage.getItem("cartProducts")) || [];
      setCart(cartItems);
    }

    setUpdate(false);
    calculateTotalPrice(cartItems);
  }, [slugFromUrl, update]);

  const updateCart = (index, newQty, low, high) => {
    // console.log(low)
    if (newQty === 0) {
      if (type == 2) {
        if (newQty < low) {
          toast.error("Minimum order quantity should be " + low);
          return;
        }
        if (newQty > high) {
          toast.error("Maximum order quantity should not be more than " + high);
          return;
        }
        const updatedCart = cartpos.filter((item, i) => i !== index);
        localStorage.setItem("cartPOS", JSON.stringify(updatedCart));
        setUpdate(true);
      } else {
        const updatedCart = cart.filter((item, i) => i !== index);
        localStorage.setItem("cartProducts", JSON.stringify(updatedCart));
        setUpdate(true);
      }
    } else {
      if (type == 2) {
        if (newQty < low) {
          toast.error("Minimum order quantity should be " + low);
          return;
        }
        if (newQty > high) {
          toast.error("Maximum order quantity should not be more than " + high);
          return;
        }
        const updatedCart = [...cart];
        updatedCart[index].qty = newQty;
        localStorage.setItem("cartPOS", JSON.stringify(updatedCart));
        setUpdate(true);
      } else {
        const updatedCart = [...cart];
        updatedCart[index].qty = newQty;
        localStorage.setItem("cartProducts", JSON.stringify(updatedCart));
        setUpdate(true);
      }
    }
  };

  const updateCustomer = (index, selectedOption) => {
    const foundCustomer = customers.find(
      (item) => item?.id === selectedOption?.id
    );

    if (type === 2) {
      const updatedCart = [...cart];
      updatedCart[index].customer = selectedOption.id;
      updatedCart[index].bif = foundCustomer?.pos_budget;
      localStorage.setItem("cartPOS", JSON.stringify(updatedCart));
      setCart(updatedCart);
    } else {
      const updatedCart = [...cart];
      updatedCart[index].customer = selectedOption.id;
      updatedCart[index].bif = foundCustomer?.bif;
      localStorage.setItem("cartProducts", JSON.stringify(updatedCart));
      setCart(updatedCart);
    }
  };

  const get_all_customers = async () => {
    const payload = {
      token: getLoggedObject()?.token,
    };
    const { isError, data } = await doPost(payload, "auth/get_all_customers");
    if (isError) {
      toast.error("Something went wrong with server.");
      setLoading(false);
    } else {
      if (data.action == "success") {
        setCustomers(data?.data);
        setProjects(data?.projects);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(data?.error);
      }
    }
  };

  const deleteItemFromCart = async (index) => {
    if (type == 2) {
      var cartItems = JSON.parse(localStorage.getItem("cartPOS")) || [];
      if (index >= 0 && index < cartItems.length) {
        cartItems.splice(index, 1);
        localStorage.setItem("cartPOS", JSON.stringify(cartItems));
        setUpdate(true);
      }
    } else {
      var cartItems = JSON.parse(localStorage.getItem("cartProducts")) || [];
      if (index >= 0 && index < cartItems.length) {
        cartItems.splice(index, 1);
        localStorage.setItem("cartProducts", JSON.stringify(cartItems));
        setUpdate(true);
      }
    }
  };

  const UpdateBreifMessage = async (index, message) => {
    if (type == 1) {
      var cartItems = JSON.parse(localStorage.getItem("cartProducts")) || [];
      if (index >= 0 && index < cartItems.length) {
        cartItems[index].brief_message = message;
        localStorage.setItem("cartProducts", JSON.stringify(cartItems));
        setUpdate(true);
      }
    } else {
      var cartItems = JSON.parse(localStorage.getItem("cartPOS")) || [];
      if (index >= 0 && index < cartItems.length) {
        cartItems[index].brief_message = message;
        localStorage.setItem("cartPOS", JSON.stringify(cartItems));
        setUpdate(true);
      }
    }
  };

  const copyItem = async (sourceIndex) => {
    if (type == 2) {
      var cartItems = JSON.parse(localStorage.getItem("cartPOS")) || [];
      if (sourceIndex >= 0 && sourceIndex < cartItems.length) {
        const itemToCopy = cartItems[sourceIndex];
        const targetIndex = cartItems.length;
        cartItems.splice(targetIndex, 0, itemToCopy);
        localStorage.setItem("cartPOS", JSON.stringify(cartItems));
        setUpdate(true);
      }
    } else {
      var cartItems = JSON.parse(localStorage.getItem("cartProducts")) || [];
      if (sourceIndex >= 0 && sourceIndex < cartItems.length) {
        const itemToCopy = cartItems[sourceIndex];
        const targetIndex = cartItems.length;
        cartItems.splice(targetIndex, 0, itemToCopy);
        localStorage.setItem("cartProducts", JSON.stringify(cartItems));
        setUpdate(true);
      }
    }
  };

  const [loadingConfirm, setloadingConfirm] = useState(false);

  const do_confirm_ = async () => {
    if (loading) {
      return;
    }
    if (type == 2) {
      var cartItems = JSON.parse(localStorage.getItem("cartPOS")) || [];
    } else {
      var cartItems = JSON.parse(localStorage.getItem("cartProducts")) || [];
    }

    const anyCustomerZero = cartItems.some((item) => item.customer === 0);
    if (anyCustomerZero) {
      toast.error("All items customers should be selected.");
      return;
    }
    // if (projectselect == "" && edit == 0) {
    //   toast.error("Please select a project.");
    //   return;
    // }

    setLoading(true);
    // INSERT ORDeR AND NAVIGATE
    const payload = {
      token: getLoggedObject()?.token,
      cart: cartItems,
      project: projectselect,
      deliverydate: finaldate,
      type: type,
    };

    // console.log(payload);
    // return
    setloadingConfirm(true);
    const { isError, data } = await doPost(payload, "auth/do_store_order");
    if (isError) {
      setLoading(false);
      setloadingConfirm(false);
    } else {
      if (data.action == "success") {
        if (type == 2) {
          localStorage.removeItem("cartPOS");
          setCart([]);
          setloadingConfirm(false);
        } else {
          localStorage.removeItem("cartProducts");
          setCart([]);
          setloadingConfirm(false);
        }
        toast.success("Your order has been created successfully!");
        if (type == 1) {
          navigate("/checkout", {
            state: {
              id: 1,
            },
          });
        } else {
          navigate("/orders");
        }
      } else {
        setLoading(false);
        toast.error(data?.message);
        setloadingConfirm(false);
        setCart([]);
        localStorage.removeItem("cartProducts");
        navigate("/products");
      }
    }
  };

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const tomorrowFormatted = tomorrow.toISOString().split("T")[0];

  useEffect(() => {
    let options = customers.map((item) => {
      return { value: item?.title, label: item?.title, id: item?.id };
    });
    setOptions(options);
  }, [customers]);

  useEffect(() => {
    const maxLeadTime = cart.reduce((max, item) => {
      return Math.max(max, item.lead_time);
    }, -Infinity);

    const currentDate = new Date();

    currentDate.setDate(currentDate.getDate() + maxLeadTime);

    const formattedDate = `${
      currentDate.getMonth() + 1
    }/${currentDate.getDate()}/${currentDate.getFullYear()}`;

    setfinalDate(formattedDate);
  }, [cart]);

  const handleCancel = () => {
    setCart([]);
    if (type == 2) {
      localStorage.removeItem("cartPOS");
    } else {
      localStorage.removeItem("cartProducts");
    }
    navigate(type == 2 ? "/pos" : "/products");
  };

  return (
    <div className="container">
      <div className="wrapper-full">
        <div className="page-content">
          <SideBar />
          <div className="right_side_bar">
            <Header title={"Cart"} />
            <div className="content_inner">
              <button
                type="button"
                onClick={() => navigate("/products")}
                className="button_followup ml-0"
              >
                Back
              </button>

              <div className="card wd100 mt-10px ">
                <div className="responsive_table">
                  <table className="wd100 table_wrap">
                    <tr>
                      <th></th>
                      <th></th>
                      <th>PRODUCT</th>

                      <th style={{ width: "150px" }}>
                        {" "}
                        {type == 2 ? "INSTRUCTIONS" : "BRIEF MESSAGE"}{" "}
                      </th>

                      <th style={{ width: "150px" }}></th>
                      <th style={{ width: "100px" }}>CUSTOMER</th>
                      <th style={{ textAlign: "center" }}>QUANTITY</th>
                      {/* <th>BRAND</th> */}
                      <th>UNIT PRICE</th>
                      <th>TOTAL PRICE</th>
                      <th></th>
                    </tr>

                    {cart.length == 0 && !loading && (
                      <tr className="cart_tr_class">
                        <td
                          colSpan={9}
                          style={{ textAlign: "center", color: "red" }}
                        >
                          No Item in cart!
                        </td>
                      </tr>
                    )}

                    {cart.map((item, index) => (
                      <tr key={item.id} className="cart_tr_class">
                        <td>{index + 1}.</td>
                        <td>
                          <img
                            src={item.image}
                            style={{
                              width: "40px",
                              height: "40px",
                              objectFit: "cover",
                              borderRadius: "10px",
                            }}
                            alt={`Product ${item.id}`}
                          />
                        </td>
                        <td>
                          <Link to={`/product/details/${item.id}`}>
                            {item.title}
                          </Link>
                        </td>
                        <td>
                          <textarea
                            className="CartTextArea"
                            style={{
                              height: "40px",
                              backgroundColor: "#424350",
                              color: "#a0a0a0",
                              padding: "10px",
                              borderRadius: "5px",
                              border: "none",
                              resize: "none",
                            }}
                            onChange={(event) =>
                              UpdateBreifMessage(index, event.target.value)
                            }
                            placeholder={`${
                              type == 2 ? "ENTER INSTRUCTIONS" : "ENTER MESSAGE"
                            }`}
                          />
                        </td>
                        <td>
                          {item?.customer != 0 && (
                            <>
                              <p
                                style={{
                                  textAlign: "end",
                                  fontSize: "10px",
                                  marginBottom: "4px",
                                }}
                              >
                                Bal: {item?.bif?.remaining_balance}
                              </p>
                              <div className="MainProgressCart">
                                <div
                                  style={{
                                    backgroundColor: `${
                                      item?.bif?.amount_perc == 100 ||
                                      item?.bif?.remaining_balance == 0
                                        ? "#955e5e"
                                        : "#29a392"
                                    }`,
                                    height: "100%",
                                    width: `${item?.bif?.bif?.amount_perc}%`,
                                    borderTopRightRadius: "50px",
                                    borderBottomRightRadius: "50px",
                                  }}
                                ></div>
                              </div>
                            </>
                          )}
                        </td>

                        <td>
                          <Select
                            styles={customStyles}
                            name="vendor"
                            value={options.find(
                              (option) => option.id === item.customer
                            )}
                            onChange={(selectedOption) =>
                              updateCustomer(index, selectedOption)
                            }
                            options={options}
                            placeholder="--Select Customer--"
                          />
                        </td>
                        <td>
                          <span
                            className="item_count item_count_dark"
                            style={{ width: "65px", margin: "auto" }}
                          >
                            <span
                              onClick={() =>
                                updateCart(
                                  index,
                                  item.qty - 1,
                                  item.min_order,
                                  item.max_order
                                )
                              }
                            >
                              <AiOutlineMinus />
                            </span>
                            <span
                              className="count_item"
                              contentEditable="true"
                              onBlur={(e) =>
                                updateCart(
                                  index,
                                  e.target.textContent.trim(),
                                  item.min_order,
                                  item.max_order
                                )
                              }
                            >
                              {item.qty}
                            </span>
                            <span
                              onClick={() =>
                                updateCart(
                                  index,
                                  parseFloat(item.qty) + 1,
                                  item.min_order,
                                  item.max_order
                                )
                              }
                            >
                              <AiOutlinePlus />
                            </span>
                          </span>
                        </td>
                        {/* <td>{item.brand}</td> */}
                        <td>{item.price}</td>
                        <td>{parseFloat(item.price * item.qty).toFixed(2)}</td>
                        <td style={{ width: "65px" }}>
                          <span className="flex-start">
                            <img
                              src={DelIcon}
                              className="cursor"
                              style={{ marginRight: "10px" }}
                              alt="Delete"
                              onClick={() => {
                                deleteItemFromCart(index);
                              }}
                            />
                            <img
                              src={CopyIcon}
                              className="cursor"
                              alt="Copy"
                              onClick={() => {
                                copyItem(index);
                              }}
                            />
                          </span>
                        </td>
                      </tr>
                    ))}
                  </table>
                </div>
                {cart.length > 0 && (
                  <>
                    {/* <div className="bottom_final_amount ">
                      <div className="mid_final_amount">
                        <div className="flex-space-between-start">
                          <div className="cart_selection">
                            {type == 1 && (
                              <div className="form-group">
                                <label>Expect Date:</label>
                                <p
                                  style={{
                                    color: "#AEB6BF ",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  className="form-control"
                                >
                                  {finaldate}
                                </p>
                              </div>
                            )}
                            <div className="form-group">
                              <label>Select Project:</label>
                              <select
                                name="vendor"
                                className="form-control"
                                value={projectselect}
                                onChange={(e) =>
                                  setProjectselect(e.target.value)
                                }
                              >
                                <option value={""}>--Select Project--</option>
                                {projects.map((v, i) => (
                                  <option key={i} value={v.id}>
                                    {v.title}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="right_total_amot">
                            <div className="pro_sub_details">
                              <span className="heading_pro_left">
                                Sub Total
                              </span>
                              <span>{parseFloat(total).toFixed(2)} AED</span>
                            </div>
                            <div className="pro_sub_details">
                              <span className="cart_line"></span>
                            </div>
                            <div className="pro_sub_details">
                              <span className="heading_pro_left">TOTAL</span>
                              <span>{parseFloat(total).toFixed(2)} AED</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    {/* onClick={()=>navigate(edit==0?"/checkout":"/orders")} */}
                    <div className="mt-10px cart_all_buttons">
                      <button
                        type="button"
                        disabled={loadingConfirm}
                        onClick={() =>
                          navigate(type == 2 ? "/pos" : "/products")
                        }
                        className="button_followup ml-0"
                      >
                        Add More
                      </button>
                      <button
                        disabled={loadingConfirm}
                        type="button"
                        onClick={() => handleCancel()}
                        className="button_followup"
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        disabled={loadingConfirm}
                        className="button_followup"
                        onClick={() => do_confirm_()}
                      >
                        {loadingConfirm ? (
                          <Loader />
                        ) : type === 1 ? (
                          "Confirm & Preview"
                        ) : (
                          "Confirm"
                        )}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
