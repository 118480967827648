import { useRoutes, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { doPost } from "../utils/apiCalls";
import { toast } from "react-toastify";
import useAuth from "../hooks/useAuth";
import Login from "../pages/Login";
import ResetPassword from "../pages/Forgot/Reset";
import Forgot from "../pages/Forgot";
import OTP from "../pages/Forgot/Otp";
import Dashboard from "../pages/Dashboard";
import DashboardSuppliers from "../pages/Dashboard/Suppliers";
import Orders from "../pages/Orders";
import OrdersSupplier from "../pages/Orders/Suppliers";

import OrderDetaills from "../pages/Orders/OrderDetaills";
import Settings from "../pages/Settings";
import Vendors from "../pages/Vendors";
import Projects from "../pages/Settings/Projects";
import RequestType from "../pages/Settings/RequestType";
import Brands from "../pages/Settings/Brands";
import Categories from "../pages/Settings/Categories";
import Members from "../pages/Settings/Members";
import AddProducts from "../pages/Settings/AddProducts";
import AddSuppliers from "../pages/Settings/AddSuppliers";
import AddCustomer from "../pages/Settings/AddCustomer";
import OrderQuotations from "../pages/Orders/OrderQuotations";

import Customers from "../pages/Customers";
import CustomerDetaills from "../pages/Customers/CustomerDetaills";

import Products from "../pages/Products";
import ProductsDetails from "../pages/Products/ProductsDetails";
import Cart from "../pages/Products/Cart";
import Checkout from "../pages/Products/Checkout";

import BeskPoke from "../pages/Products/BeskPoke";
import BeskPokeDetails from "../pages/Products/BeskPokeDetails";
import Notifs from "../pages/Settings/Notifs";

import ChangePass from "../pages/Settings/ChangePass";
import UpdateCoustomer from "../pages/Settings/updateCustomer";
import ExportsData from "../pages/Exports";

function Router() {
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // handleLogin();
    setInterval(() => {
      handleLogin();
    }, 5000);
  }, []);

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const handleLogin = async () => {
    const prev = localStorage.getItem("PROCXAUTH");
    let token_data = JSON.parse(prev);
    if (token_data != null) {
      const payLoad = {
        token: token_data?.token,
        type: token_data?.ac_type == 9 ? 2 : 1,
      };
      const { isError, data } = await doPost(payLoad, "auth/check_login");
      if (data.error == "Invalid login credentials") {
        localStorage.removeItem("PROCXAUTH");
        setLoggedObject(null);
        toast.error("Your session has been expired!");
        navigate("/login", { replace: true });
      } else {
        const path = window.location.pathname;
        const lastSlashIndex = path.lastIndexOf("/");
        const slugFromUrl =
          lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : "";
        if (slugFromUrl != "subscriptions" && slugFromUrl != "payment") {
          const final_data = data?.data;
          var current = new Date();
          var expiry_date = new Date(final_data?.expiry_date);

          if (current > expiry_date) {
            toast.error(
              "Your subscription has been expired. Upgrade to access full functionality of the website!"
            );
            navigate("/subscriptions");
          } else {
          }
        }
      }
    }
  };

  const authRoutes = [
    { path: "/", element: <Dashboard /> },
    { path: "/dashboard", element: <Dashboard /> },
    { path: "/dashboard/suppliers", element: <DashboardSuppliers /> },
    { path: "/suppliers_orders", element: <OrdersSupplier /> },
    { path: "/orders", element: <Orders /> },
    { path: "/settings", element: <Settings /> },
    { path: "/projects", element: <Projects /> },
    { path: "/Request-Type", element: <RequestType /> },
    { path: "/brands", element: <Brands /> },
    { path: "/members", element: <Members /> },
    { path: "/add-products", element: <AddProducts /> },
    { path: "/add-suppliers", element: <AddSuppliers /> },
    { path: "/add-customers", element: <AddCustomer /> },
    { path: "/categories", element: <Categories /> },
    { path: "/vendors", element: <Vendors /> },
    { path: "/order/details/:slug", element: <OrderDetaills /> },
    { path: "/order/quotations/:slug", element: <OrderQuotations /> },
    { path: "/customers", element: <Customers /> },
    { path: "/customer/details/:slug", element: <CustomerDetaills /> },
    { path: "/products", element: <Products /> },
    { path: "/product/details/:slug", element: <ProductsDetails /> },
    { path: "/cart", element: <Cart /> },
    { path: "/checkout", element: <Checkout /> },
    { path: "/pos", element: <Products /> },
    { path: "/beskpoke/project", element: <BeskPoke /> },
    { path: "/beskpoke/details/:slug", element: <BeskPokeDetails /> },
    { path: "/notifications", element: <Notifs /> },
    { path: "/change/password", element: <ChangePass /> },
    { path: "/customers/update/:id", element: <UpdateCoustomer /> },
    { path: "/Export-reports", element: <ExportsData /> },
  ];

  const unAuthRoutes = [
    { path: "/", element: <Login /> },
    // { path: "/authloading", element: <AuthLoading /> },
    { path: "/login", element: <Login /> },
    { path: "/vendor-login", element: <Login /> },
    { path: "/forgot/password", element: <Forgot /> },
    { path: "/Otp", element: <OTP /> },
    { path: "/reset/Password", element: <ResetPassword /> },
    { path: "*", element: <Login /> },
  ];

  const routes = isLoggedIn ? authRoutes : unAuthRoutes;
  // const routes = unAuthRoutes;
  return useRoutes(routes);
}

export default Router;
